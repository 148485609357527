<template>
  <div class="DataAnalysiSeport">
    <div class="data-analysis-history-header">
      <div class="back_box">
        <span class="back" @click="goBack"
          ><img
            :src="require('assets/images/goldenSentence/back.png')"
            alt=""
          />返回</span
        >

        <div class="History">
          <img
            :src="require('assets/images/goldenSentence/History.png')"
            alt=""
          />
          历史报告
        </div>
      </div>
    </div>
    <!-- <div class="btn">
      <el-button type="primary" size="small" @click="getPdf"
        >点击下载</el-button
      >
    </div> -->
    <div id="pdfDom">
      <div class="top_box">
        <div class="title">数据分析报告</div>
        <!-- <div class="text_box">
          <div class="dataText">
            共分析{{ total.keyDetail }}，涉及{{
              total.numDetail
            }}篇文章/著作的节选数据，分析池数据规模为{{ total.indexvo }}字。
          </div>

          <div class="dataText" v-if="timeKeyDetail.length != 0">
            时间指标，从{{ timeKeyDetail[0] }}至{{
              timeKeyDetail[1]
            }}。其中，<span v-for="(item, index) in timeIndexvo" :key="index"
              >{{ item[1] }} %的分析文本原创时间为{{ item[0] }}年{{
                timeIndexvo.length == index + 1 ? "。" : "；"
              }}</span
            >
          </div>

          <div class="dataText" v-if="autKeyDetail.length != 0">
            权威性指标项，从{{ autKeyDetail[0] }}星至{{
              autKeyDetail[1]
            }}星。其中，<span v-for="(item, index) in autIndexvo" :key="index"
              >{{ item[1] }} %的分析文本权威性等级为{{ item[0] }}星{{
                autIndexvo.length == index + 1 ? "。" : "；"
              }}</span
            >
          </div>
        </div> -->

        <div class="graphic_box">
          <div class="title_box">
            <div class="item">
              <div class="title_name">关键字</div>
              <div class="title_num">总共出现的次数</div>
            </div>
            <div class="item">
              <div class="title_name">关键字</div>
              <div class="title_num">总共出现的次数</div>
            </div>

            <!-- <div class="title_count">全文字符</div>
            <div class="title_info">
              <div class="title_library">语境算法引擎</div>
            </div> -->
          </div>

          <div class="list">
            <div class="item_box" v-for="(item, index) in list" :key="index">
              <div class="item">
                <div class="item_name">{{ item.name }}</div>
                <div class="item_num">{{ item.value }}</div>
                <!-- <div class="item_count">
                全文{{ item.num }}篇 {{ item.count }}字符
              </div>
              <div class="info_box">
                <div
                  class="item_info"
                  v-for="(item1, index1) in item.listGraphic"
                  :key="index1"
                >
                  <div class="item_library">
                    其中{{ item1.library }}出现{{ item1.value }}次
                  </div>
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="myChart" v-for="(item, index) in cakelist" :key="index">
        <div :id="'myChart' + index" :style="{ height: '500px' }"></div>
      </div>

      <!-- <div class="mybar" v-for="(item, index) in cakelist" :key="index">
        <div :id="'mybar' + index" :style="{ height: '500px' }"></div>
      </div> -->

      <div class="mybar" v-for="(item, index) in echartsList" :key="index">
        {{ index + 1 }}.{{ item.name }}。关联度靠前为:
        <span v-for="(v, i) in item.data" :key="i"
          >{{ v.name }}{{ item.data.length != i + 1 ? "、" : "。" }}</span
        >
        <div :id="'mybar1' + index" :style="{ height: '500px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { AnalysisReport } from "api/infoCompile";
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
export default {
  data() {
    return {
      list: [],
      throttle: true,
      echartsList: [],
      autIndexvo: [],
      autKeyDetail: [],
      timeIndexvo: [],
      timeKeyDetail: [],
      total: "",
      cakelist: [],
    };
  },
  methods: {
    //返回
    goBack() {
      this.$router.push({
        name: "DataAnalysisHistory",
      });
    },

    // 获取历史报告
    async getSeportlist(analysisId) {
      let form = new FormData();
      form.append("analysisId", analysisId);

      const res = await AnalysisReport(form);
      if (res.code == 200) {
        this.list = res.data.echarts;

        // let arr = res.data.echarts((item) => {
        //   return {
        //     value: item.value,
        //     name: item.name,
        //   };
        // });
        // this.mybarfun(res.data.echarts);
        // this.drawLine(res.data.echarts);

        let arr1 = [];
        this.list.forEach((item, i) => {
          if (i % 50 == 0) {
            arr1.push(this.list.slice(i, i + 50));
          }
        });

        this.cakelist = arr1;

        this.cakelist.forEach((item, index) => {
          this.$nextTick(() => {
            this.drawLine(item, index);

            // this.mybarfun(item, index);
          });
        });

        let arr = [];

        for (const key in res.data.relation) {
          arr.push({
            name: key,
            data: res.data.relation[key],
          });
        }

        this.echartsList = arr;
        // console.log(arr);
        this.echartsList.forEach((item, index) => {
          this.$nextTick(() => {
            this.mybarfunlist(item.data, index);
          });
        });

        try {
          this.timeIndexvo = res.data.time["0"].indexvo
            .split(",")
            .map((item) => {
              return item.split("==");
            });
        } catch (error) {
          console.log(error);
        }
        try {
          this.timeKeyDetail = res.data.time["0"].keyDetail.split("==");
        } catch (error) {
          console.log(error);
        }

        try {
          this.autIndexvo = res.data.aut[0].indexvo.split(",").map((item) => {
            return item.split("==");
          });
        } catch (error) {
          console.log(error);
        }
        try {
          this.autKeyDetail = res.data.aut[0].keyDetail.split("==");
        } catch (error) {
          console.log(error);
        }

        this.total = res.data.total[0];
      }
    },
    // 饼形图
    drawLine(data, index) {
      let sum = 0;
      data.forEach((item) => {
        sum += item.value;
      });

      // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
      let myChart = this.$echarts.init(
        document.getElementById("myChart" + index),
        null,
        { renderer: "svg" }
      );
      // 绘制图表
      myChart.setOption({
        title: {
          text: "总数", //主标题文本
          subtext: sum, //副标题文本
          // left: "36%",
          top: "60",
          textStyle: {
            fontSize: "20",
            color: "#0073CD",
            align: "center",
            fontWeight: "500",
          },
          subtextStyle: {
            fontSize: "26",
            color: "#0073CD",
            align: "center",
            fontWeight: "800",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}  {c}  ( {d}% )",
        },

        legend: {
          type: "scroll",
          orient: "horizontal",
          top: 20,
          icon: "circle",

          formatter(name) {
            var tarValue;
            for (var i = 0; i < data.length; i++) {
              if (data[i].name == name) {
                tarValue = data[i].value;
              }
            }
            var v = tarValue;
            return [`${name}   ${v}`];
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            right: "left",
            label: {
              show: true,
              position: "outside",
              formatter: "{b}  {c}",
            },
            data: data,
          },
        ],
      });
    },
    // 柱形图
    mybarfun(data, index) {
      let arrName = [];
      let arrValue = [];
      arrName = data.map((item) => {
        return item.name;
      });
      arrValue = data.map((item) => {
        return item.value;
      });

      // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
      let myChart = this.$echarts.init(
        document.getElementById("mybar" + index),
        null,
        {
          renderer: "svg",
        }
      );
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: arrName,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            barWidth: "7%",
            data: arrValue,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      });
    },

    // echartsList
    mybarfunlist(data, index) {
      let arrName = [];
      let arrValue = [];
      arrName = data.map((item) => {
        return item.name;
      });
      arrValue = data.map((item) => {
        return item.value;
      });

      // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
      let myChart = this.$echarts.init(
        document.getElementById("mybar1" + index),
        null,
        {
          renderer: "svg",
        }
      );
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: arrName,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            barWidth: "7%",
            data: arrValue,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
          {
            data: arrValue,
            type: "line",
            showBackground: true,
          },
        ],
      });
    },

    // 下载
    getPdf() {
      if (!this.throttle) {
        this.$message("正在整理数据资源");
        return;
      }
      var shareContent = document.querySelector("#pdfDom");
      var width = shareContent.offsetWidth / 4;
      var height = shareContent.offsetHeight / 4;
      this.throttle = false;
      let _this = this;
      html2Canvas(document.querySelector("#pdfDom"), {
        allowTaint: true,
        dpi: 300,
        scale: 2,
      }).then(function (canvas) {
        var context = canvas.getContext("2d");
        context.mozImageSmoothingEnabled = false;
        context.webkitImageSmoothingEnabled = false;
        context.msImageSmoothingEnabled = false;
        context.imageSmoothingEnabled = false;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var img = new Image();
        var pdf;
        img.src = pageData;
        img.onload = function () {
          // 获取dom高度、宽度
          img.width = img.width / 2;
          img.height = img.height / 2;
          img.style.transform = "scale(0.5)";
          if (width > height) {
            // 此可以根据打印的大小进行自动调节
            pdf = new JsPDF("l", "mm", [width * 1, height * 1]);
          } else {
            pdf = new JsPDF("p", "mm", [width * 1, height * 1]);
          }
          pdf.addImage(pageData, "jpeg", 0, 0, width * 1, height * 1);
          pdf.save("数据分析报告" + ".pdf");
          _this.throttle = true;
        };
      });
    },
  },
  mounted() {},
  created() {
    this.getSeportlist(this.$route.query.id);
  },
};
</script>

<style lang="scss" scoped>
.DataAnalysiSeport {
  box-sizing: border-box;

  .data-analysis-history-header {
    padding: 20px;
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    .back_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .back {
      @include noselect;
      img {
        width: 6px;
        height: 9px;
        margin-right: 6px;
        vertical-align: middle;
      }
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
    }

    .History {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 5px;
        width: 13px;
        height: 13px;
      }
    }
  }

  .btn {
    padding: 0 10px;
  }

  #pdfDom {
    padding: 0 10px;
  }

  .top_box {
    .title {
      text-align: center;
      font-weight: 800;
      font-size: 18px;
    }

    .text_box {
      margin-bottom: 20px;
      .dataText {
        text-indent: 2em;
        line-height: 1.8;
      }
    }

    .graphic_box {
      font-size: 12px;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;

      .title_box {
        font-weight: 800;

        display: flex;
        border-bottom: 1px solid #ccc;
        .item {
          width: 50%;
          display: flex;
        }
        .title_name {
          flex: 25%;
          border-right: 1px solid #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;
        }

        .title_num {
          flex: 20%;
          border-right: 1px solid #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;
        }

        .title_count {
          width: 25%;
          border-right: 1px solid #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;
        }

        .title_info {
          flex: 35%;
          border-right: 1px solid #ccc;
          display: flex;
          justify-content: center;
          padding: 15px;

          .title_library {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        .item_box {
          width: 50%;
          .item {
            border-bottom: 1px solid #ccc;
            display: flex;

            .item_name {
              flex: 25%;
              border-right: 1px solid #ccc;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 15px;
            }

            .item_num {
              flex: 20%;
              border-right: 1px solid #ccc;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 15px;
            }

            .item_count {
              flex: 25%;
              border-right: 1px solid #ccc;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 15px;
            }

            .info_box {
              flex: 35%;
              border-right: 1px solid #ccc;
              padding: 15px;
              .item_info {
                display: flex;
                align-items: center;
                line-height: 2;
                .item_library {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .title {
    padding: 20px 0;
    font-weight: 800;
    font-size: 22px;
  }

  .mybar {
    span {
      line-height: 1.5;
    }
  }
}
</style>
