import request from "./request";
import crewordRequest from "./crewordRequest";

// 历史报告
export function AnalysisReport(data) {
  return crewordRequest({
    url: "/analysis/report",
    method: "post",
    data,
  });
}
// 收藏列表
export function saveToCollect(data) {
  return request({
    url: "/disk/saveToCollect",
    method: "post",
    data,
  });
}

// 历史报告
export function AnalysisList() {
  return crewordRequest({
    url: "/analysis/list",
    method: "post",
  });
}

// 是否购买
export function openLibraryFile(data) {
  return crewordRequest({
    url: "/analysis/openLibraryFile",
    method: "post",
    data,
  });
}

// 是否完成
export function AnalysisIsfinish(data) {
  return crewordRequest({
    url: "/analysis/isfinish",
    method: "post",
    data,
  });
}

// 开始分析
export function AnalysisStart(data) {
  return crewordRequest({
    url: "/analysis/start",
    method: "post",
    data,
  });
}

//菜单列表
export function list(data) {
  return request({
    url: "/menubase/list",
    method: "post",
    data,
  });
}

//首页搜索
export function queryOfficicalWord(data) {
  return crewordRequest({
    url: "/copyright/queryOfficicalWord",
    method: "post",
    data,
  });
}

// 添加汇编
export function addAssemblys(data) {
  return request({
    url: "/system/addDocTempInfo",
    method: "post",
    data,
  });
}

// 获取汇编成册数量
export function getAssemblyNums(data) {
  return request({
    url: "/system/userDocTempCount",
    method: "post",
    data,
  });
}

// 信息汇编-文件下载
export function fileDownload(data) {
  return request({
    url: "/system/writerCkWord",
    method: "post",
    data,
    responseType: "blob",
  });
}

// 转存网盘
export function saveNetdisk(data) {
  return request({
    url: "/system/shareToIcloud",
    method: "post",
    data,
  });
}

//获取全文
export function getAlltext(data) {
  return request({
    url: "/system/openCkWord",
    method: "post",
    data,
  });
}

// 目录层级
export function directory(data) {
  return request({
    method: "post",
    url: "/disk/directory",
    data,
  });
}

// 转存网盘
export function Collection(data) {
  return request({
    url: "/favorite/collection",
    method: "post",
    data,
  });
}

//汇编成册
export function writerMergeWord() {
  return request({
    url: "/system/writerMergeWordToServe",
    method: "post",
  });
}

//清空汇编
export function delDocTempInfo() {
  return request({
    url: "/system/delDocTempInfo",
    method: "post",
  });
}

//  查询选中库
export function findSelectedLib(params) {
  return crewordRequest({
    url: "/contextual/findSelectedLib",
    method: "get",
    params,
  });
}

//  获取上传文件的字数
export function wordnum(data) {
  return crewordRequest({
    url: "/analysis/wordnum",
    method: "post",
    data,
  });
}
//  查询选中库
export function DataDelete(data) {
  return crewordRequest({
    url: "/analysis/delete",
    method: "post",
    data,
  });
}

// 根据orderid查询订单详情
export function analysisSearch(data) {
  return crewordRequest({
    url: "/analysis/search",
    method: "post",
    data,
  });
}
// 根据orderid查询订单详情
export function analysisPreselectEdit(data) {
  return crewordRequest({
    url: "/analysisPreselect/edit",
    method: "post",
    data,
  });
}

// 词频分析字数
export function analysisFrequencyAnalysis() {
  return crewordRequest({
    url: "/analysis/frequencyAnalysis",
    method: "post",
  });
}

// 收藏列表
export function querylCollectionWord(data) {
  return crewordRequest({
    url: "/copyright/querylCollectionWord",
    method: "post",
    data,
  });
}
// 是否购买
export function openLibraryTraceSourceFile(data) {
  return crewordRequest({
    url: "/analysis/openLibraryTraceSourceFile",
    method: "post",
    data,
  });
}

//获取文件路径
export function findTextUrl(params) {
  return crewordRequest({
    url: "/analysis/findTextUrl",
    method: "get",
    params,
  });
}

//  查看全文
export function isView() {
  return crewordRequest({
    url: "/contextual/isView",
    method: "post",
  });
}

// 选择网盘文件上传
export function diskFlieUpload(data) {
  return request({
    url: "/uploader/diskUpload",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export function checkPortfolioAuthorization(data) {
  return request({
    url: "/portfolio/checkPortfolioAuthorization",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

// 选中列表
export function analysisTextList(data) {
  return crewordRequest({
    url: "/analysis/textList",
    method: "post",
    data,
  });
}

// 删除文件
export function analysisPreview(data) {
  return crewordRequest({
    url: "/analysis/preview",
    method: "post",
    data,
  });
}

// 开始分析
export function analysisGetBdParticiple(data) {
  return crewordRequest({
    url: "/analysis/getBdParticiple",
    method: "post",
    data,
  });
}

// 删除文件
export function analysisDelFile(data) {
  return crewordRequest({
    url: "/analysis/delFile",
    method: "post",
    data,
  });
}

//全部删除
export function allDelete(data) {
  return crewordRequest({
    url: "/analysisPreselect/delete",
    method: "post",
    data,
  });
}

// 汇编成册列表
export function assemblyList(data) {
  return request({
    url: "/system/assemblyList",
    method: "post",
    data,
  });
}

// 汇编成册查询状态
export function checkAssembly(data) {
  return request({
    url: "/system/checkAssembly",
    method: "post",
    data,
  });
}

// 查询添加汇编列表
export function queryAssembleWord(data) {
  return crewordRequest({
    url: "/copyright/queryAssembleWord",
    method: "post",
    data,
  });
}
// system/delAssembleByIds
export function delAssembleByIds(data) {
  return request({
    url: "/system/delAssembleByIds",
    method: "post",
    data,
  });
}

export function analysisPreselectInsert(data) {
  return crewordRequest({
    url: "/analysisPreselect/insert",
    method: "post",
    data,
  });
}

export function analysisPreselectDelete2(data) {
  return crewordRequest({
    url: "/analysisPreselect/delete2",
    method: "post",
    data,
  });
}

export function keyword(data) {
  return crewordRequest({
    url: "/analysis/keyword",
    method: "post",
    data,
  });
}
